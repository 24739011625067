import React from 'react'
import { FaWhatsapp } from "react-icons/fa";

const FloatingComponent = () => {
    return (
        <a href="https://wa.api-whatsapp.in/bglS5n" className="float" target="_blank" rel="noreferrer">
            <FaWhatsapp className='my-float' />

        </a>
    )
}

export default FloatingComponent
