import React from 'react'
import * as common from '../component'

const Home = () => {
  return (
    <>
      {/* y */}
      <common.Banner />
      <common.Prime />
      <common.Amentinies />
      <common.Location />
      <common.About />
      <common.Facing /> 
      <common.CommonBackground child="testimony"/>
      <common.Offer />
      <common.CommonBackground child="ready"/>
      <common.Footer />
    </>
  )
}

export default Home
