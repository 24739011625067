import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import img1 from '../images/facing1.webp'
import img2 from '../images/facing2.webp'
import { useMediaQuery } from 'react-responsive'

const Facing = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 991px)' });
    return (
        <div className='facing-section py-50' id="facing">
            <Container>
                <Row >
                    <Col  lg={6} md={12} xs={12} className={`${isMobile ? 'mb-3' : ''}`}>
                    <div className='card facing-div border-0' style={{backgroundColor: '#FFF7EB'}}>
                        <Row className='align-items-center'>
                            <Col sm={5} xs={6}>
                                <img src={img1} alt="facing-east" className='img-fluid mr-20'></img>
                            </Col>
                            <Col sm={7} xs={6}>
                                <div>
                                    <p>3BHK</p>
                                    <p>Type 1</p>
                                    <ul>
                                        <li>Unit: 3BHK</li>
                                        <li>Facing: East</li>
                                        <li>Area: 2455 SFT</li>
                                        <li>Flat No: 15</li>
                                    </ul>
                                    <button className='common-btn text-white primary-background'><a href="https://wa.api-whatsapp.in/bglS5n" className='text-decoration-none text-white' target="_blank" rel="noreferrer">Download Floor Plan</a></button>
                                </div>
                            </Col>
                        </Row>
                        </div>
                    </Col>
                    <Col lg={6} md={12} xs={12}>
                        <div className='card facing-div' style={{border: '1px solid #C5A67A'}}>
                            <Row className='align-items-center'>
                                <Col sm={5} xs={6}>
                                    <img src={img1} alt="facing-east" className='img-fluid mr-20'></img>
                                </Col>
                                <Col sm={7} xs={6}>
                                    <div>
                                        <p>3BHK</p>
                                        <p>Type 2</p>
                                        <ul>
                                            <li>Unit: 3BHK</li>
                                            <li>Facing: West</li>
                                            <li>Area: 2455 SFT</li>
                                            <li>Flat No: 05</li>
                                        </ul>
                                        <button className='common-btn primary-color west-btn' style={{backgroundColor: '#fff'}}><a href="https://wa.api-whatsapp.in/bglS5n" className='text-decoration-none primary-color' target="_blank" rel="noreferrer">Download Floor Plan</a></button>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        {/* <div className='d-flex align-items-center facing-div west-facing bg-light'>
                            <img src={img2} alt="facing-west" className='img-fluid mr-20'></img>
                            <div>
                                <p>3BHK</p>
                                <p>Type 2</p>
                                <ul>
                                    <li>Unit: 3BHK</li>
                                    <li>Facing: West</li>
                                    <li>Area: 2455 SFT</li>
                                    <li>Flat No: 05</li>
                                </ul>
                                <button className='common-btn primary-color west-btn bg-light'>Download Floor Plan</button>
                            </div>
                        </div> */}

                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Facing
