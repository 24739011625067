import React from 'react'
import { Container } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive'
import { setToggleTrue } from '../redux/slice/changeState'
import { useDispatch } from 'react-redux';

const Ready = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 425px)' });
  const dispatch = useDispatch()
  return (
    <div className='ready-section text-center'>
        <Container>
          {
            isMobile ?
            <>
            <h2 className='text-capitalize'>Ready to Make</h2>
            <h2 className='mb-3 text-capitalize'><span>IRA Miracle</span> Your Home?</h2>
            <h6>Don’t miss out on the opportunity</h6>
            <h6>to own one of Kollur’s best 3 BHK</h6>
            <h6 className='mb-5'>apartments.</h6>
            </>
            :
            <>
            <h2 className='text-capitalize'>Ready to Make <span>IRA Miracle</span> Your Home?</h2>
            <h6>Don’t miss out on the opportunity to own one of Kollur’s best 3 BHK apartments.</h6>
            </>
          }
        
        <div className='d-flex justify-content-center button-section'>
            <button className='common-btn primary-background text-white flex-grow-1' onClick={() => dispatch(setToggleTrue())}>Schedule a Site Visit</button>
            <button className='common-btn text-white flex-grow-1'><a href="https://wa.api-whatsapp.in/bglS5n" className='text-decoration-none text-white' target="_blank" rel="noreferrer">Chat on Whatsapp</a></button>
        </div>
        </Container>
    </div>
  )
}

export default Ready
